<template>
  <router-link class="section-header-link" :class="theme" :to="link">
    <component :is="tag">
      <span v-html="splitString(text, false)" />&nbsp;&#x200B;
      <span class="white-space-nowrap"
        ><span v-html="splitString(text, true)" /><span style="margin-left: -8px">&nbsp;</span
        ><IconSVG :svg="IconAngleRight" :size="tag === 'h5' || tag === 'h6' ? 16 : 24" />
      </span>
    </component>
  </router-link>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconAngleRight from 'src/svg/angle-right.svg';

@Component({ components: { IconSVG } })
export default class SectionHeaderLink extends SequoiaComponent {
  IconAngleRight = IconAngleRight;

  @Prop({ default: 'h3' })
  tag!: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  @Prop({ required: true })
  link!: string;

  @Prop({ required: true })
  text!: string;

  splitString(str: string, lastWord: boolean) {
    const strArray: Array<string> = str?.trim()?.split(' ');

    if (lastWord) {
      return strArray?.[strArray?.length - 1];
    } else {
      return strArray?.slice(0, strArray?.length - 1).join(' ');
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.section-header-link {
  display: block;
  cursor: pointer;

  .icon {
    display: inline-block;
    margin-left: 4px;
    transition: var(--ease-out) 0.15s;
  }

  @include devices-with-hover {
    &:hover,
    &.active {
      .icon {
        margin-left: 12px;
      }
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    color: var(--c-light-font-primary);

    .icon {
      color: var(--alpha-light-7);
    }

    @include devices-with-hover {
      &:hover .icon {
        color: var(--alpha-light-8);
      }
    }

    &:active .icon {
      color: var(--alpha-light-9);
    }
  }

  &.dark {
    color: var(--c-dark-font-primary);

    .icon {
      color: var(--alpha-dark-7);
    }

    @include devices-with-hover {
      &:hover .icon {
        color: var(--alpha-dark-8);
      }
    }

    &:active .icon {
      color: var(--alpha-dark-9);
    }
  }
}
</style>
