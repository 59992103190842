var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vod-recommendations",attrs:{"data-cy":"vod-recommendations"}},[(!_vm.recommendationsLoaded)?_c('LoaderSpinner'):_vm._l((_vm.vodWithPosters),function(vod,index){return _c('section',{key:'vod' + index,staticClass:"pb-64 pb-tablet-48 pb-mobile-48 overflow-hidden",class:{ 'pb-16': index === _vm.vodWithPosters.length - 1 },attrs:{"data-cy":"vod-recommendation"}},[_c('SectionHeaderLink',{staticClass:"with-side-padding",attrs:{"text":vod.title,"link":vod.link},nativeOn:{"click":function($event){return _vm.gaEvent({
          action: 'Клик по заголовку стрипа',
          category: _vm.gaEventCategory,
          strip_name: vod.title,
        })}}}),_c('HomeContentSlider',{attrs:{"type":"adaptive","theme-forced":_vm.theme},on:{"gaScroll":function($event){return _vm.gaScroll(vod.title)}}},[_vm._l((_vm.getVodPosters(vod)),function(poster){return _c('TilePoster',{key:poster.id,staticClass:"col-desktop-2 col-tablet-2 col-mobile-2",attrs:{"width-proportion":_vm.posterWidthProportion(vod.source),"height-proportion":_vm.posterHeightProportion(vod.source),"title":poster.title,"original-title":poster.originalTitle,"link":poster.link,"image":poster.image.path,"image-provider":poster.image.type,"logo":vod.logo,"rating-imdb":poster.ratingImdb,"rating-kinopoisk":poster.ratingKinopoisk,"countries":poster.countries,"years":poster.years,"genres":poster.genres,"duration":poster.duration,"title-branding-methods":poster.brandingMethods},on:{"click":function($event){_vm.gaEvent({
            action: 'Клик по тайтлу в стрипе',
            category: _vm.gaEventCategory,
            strip_name: vod.title,
            title_id: poster.link.split('/')[3],
            vod_name: vod.source,
          })}}})}),(vod.posters.length > _vm.DEFAULT_NUMBER_OF_SLIDES)?_c('div',{staticClass:"col-desktop-2 col-tablet-2 col-mobile-2"},[_c('div',{staticClass:"tile-view-more-wrapper",style:({
            paddingTop: `${
              (_vm.posterHeightProportion(vod.source) / _vm.posterWidthProportion(vod.source)) * 100
            }%`,
          })},[_c('TileViewMore',{staticClass:"tile-view-more",attrs:{"text":_vm.getTranslation('show_more'),"link":vod.link},on:{"click":function($event){return _vm.gaEvent({
                category: _vm.gaEventCategory,
                action: 'Клик по кнопке ”Показать еще”',
                strip_name: vod.title,
              })}}})],1)]):_vm._e()],2)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }