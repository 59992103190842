var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$store.vod && !_vm.isEmpty(_vm.sources))?_c('div',{staticClass:"tiles-vod-navigation-group",attrs:{"data-cy":_vm.dataCy ? `tiles-vod-navigation-group-${_vm.dataCy}` : ''}},[(_vm.showHeader)?_c('h3',{staticClass:"h3",domProps:{"innerHTML":_vm._s(_vm.getTranslation('vod'))}}):_vm._e(),_c('div',{staticClass:"row"},_vm._l((_vm.sources),function(source,i){return _c('TileVod',{key:source.id + i.toString(),class:[
        { 'col-desktop-12 col-tablet-8 col-mobile-4': _vm.getObjectSize(_vm.sources) === 1 },
        { 'col-desktop-6 col-tablet-4 col-mobile-2': _vm.getObjectSize(_vm.sources) === 2 },
        {
          'col-desktop-4 col-tablet-4 col-mobile-2': [3, 5, 6, 9].includes(
            _vm.getObjectSize(_vm.sources)
          ),
        },
        {
          'col-desktop-3 col-tablet-4 col-mobile-2': [4, 7, 8, 10].includes(
            _vm.getObjectSize(_vm.sources)
          ),
        },
      ],attrs:{"source-id":source.id,"title":source.customTitle ? _vm.getByLanguage(source.customTitle) || '' : '',"image":_vm.getByLanguage(source.imageTileVod) ? _vm.getByLanguage(source.imageTileVod).url : '',"logo":_vm.getByLanguage(source.logo) ? _vm.getByLanguage(source.logo).url : '',"color":source.color ? source.color.basic : '',"data-cy":_vm.dataCy ? `${_vm.dataCy}` : ''},on:{"gaTileClick":function($event){return _vm.$emit('gaTileClick', source.id)}}})}),1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }