<template>
  <CollectionDetails
    :caption="caption"
    :header="collection.caption"
    :description="collection.description"
    :posters="titlesList"
    :col-num="colNum"
    :row-num="rowNum"
    :width-proportion="posterWidthProportion(collection.vodSource)"
    :height-proportion="posterHeightProportion(collection.vodSource)"
  >
    <TilePoster
      v-for="(title, i) in collection.titlesList"
      ref="tilePoster"
      :key="title.id + i.toString()"
      class="col-desktop-2 col-tablet-2 col-mobile-2 mb-32"
      :width-proportion="posterWidthProportion(collection.vodSource)"
      :height-proportion="posterHeightProportion(collection.vodSource)"
      :title="title.title"
      :original-title="title.originalTitle"
      :link="prepareTitleLink(title, collection.vodSource)"
      :image="title.posters ? title.posters[0].path : ''"
      :logo="
        collection.logo
          ? collection.logo
          : title.availableOnTvChannels
          ? getChannelLogo(getChannelById(title.availableOnTvChannels[0].channelId))
          : ''
      "
      :rating-imdb="title.ratingImdb"
      :rating-kinopoisk="title.ratingKp"
      :countries="title.countries"
      :years="title.years"
      :genres="title.genres"
      :duration="title.duration"
      :title-branding-methods="title.brandingMethods"
      @click="gaClick(title.id)"
    />
    <ButtonDefault
      v-if="shouldLoadMoreTitlesInCollection && !collectionTitlesLoading"
      view="secondary"
      class="show-more mt-32 mt-tablet-16 mt-mobile-16"
      data-cy="show-more-titles"
      size="medium"
      :full-width="true"
      :with-icon="true"
      @click="loadMoreTitles()"
    >
      <IconSVG :svg="IconMoreHorizontal" />
      <span v-html="getTranslation('show_more')" />
    </ButtonDefault>

    <LoaderSpinner v-if="collectionTitlesLoading" :full-width="true" />
  </CollectionDetails>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { VODHomeCollectionEnhanced } from 'src/store/vod/types';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import { VODTitlePreview } from 'src/models/ts/vod/v2/vod';
import { TChannelEnhanced } from 'src/api/channels/types';
import { getChannelLogo } from 'src/utils/channel';
import { prepareTitleLink } from 'src/utils/vod';
import IconSVG from 'src/components/IconSVG.vue';
import IconMoreHorizontal from 'src/svg/more-horizontal.svg';
import TilePoster from 'src/components/ui/tiles/TilePoster.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import CollectionDetails from 'src/components/CollectionDetails.vue';

@Component({
  components: {
    CollectionDetails,
    TilePoster,
    ButtonDefault,
    IconSVG,
    LoaderSpinner,
  },
})
export default class VodCollectionDetails extends SequoiaComponent {
  IconMoreHorizontal = IconMoreHorizontal;

  @Prop({ required: true })
  collection!: VODHomeCollectionEnhanced;

  get sourceId() {
    return this.$route.params.sourceId;
  }

  get orientation() {
    return this.collection.vodSource === 'archive' ? 'landscape' : 'portrait';
  }

  get colNum() {
    return this.collection.vodSource === 'archive' ? 7 : 10;
  }

  get rowNum() {
    return this.collection.vodSource === 'archive' ? 7 : 5;
  }

  get titlesList() {
    return this.collection.titlesList.map(
      (title: VODTitlePreview) => title?.posters?.[0]?.path || ''
    );
  }

  get sourceText() {
    return this.collection.vodSource === 'archive'
      ? this.getTranslation('archive')
      : this.getByLanguage(this.$store.vod.sources[this.collection.vodSource]?.customTitle || '');
  }

  get caption() {
    return this.getTranslation('collection_subtitle').replace(`%vodSource%`, `${this.sourceText}`);
  }

  get collectionTitlesLoading() {
    return selectors.vod.collectionTitlesLoadingSelector(this.$store);
  }

  get shouldLoadMoreTitlesInCollection() {
    return selectors.vod.shouldLoadMoreTitlesInCollectionSelector(this.$store);
  }

  getChannelById(channelId: string) {
    return selectors.tvChannels.channelByIdSelector(this.$store, channelId);
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  prepareTitleLink(title: TVODTitlePreviewEnhanced, sourceId: string) {
    return prepareTitleLink(title, sourceId);
  }

  posterWidthProportion(source: string) {
    return selectors.vod.posterWidthProportionSelector(this.$store, source);
  }

  posterHeightProportion(source: string) {
    return selectors.vod.posterHeightProportionSelector(this.$store, source);
  }

  async loadMoreTitles() {
    this.gaEvent({
      category: 'vod',
      action: 'Клик по кнопке "Показать еще"',
      strip_name: this.collection.caption,
    });
    await actions.vod.loadTitlesForCollection(this.$store, this.collection, !!this.sourceId);
  }

  gaClick(id: string | undefined) {
    this.gaEvent({
      category: 'VOD_collection',
      action: 'title, click' + `${this.collection.caption} - ${id}`,
    });
  }
}
</script>
