<template>
  <a
    class="tile-vod overlay-default-before"
    :class="theme"
    :data-cy="dataCy ? `tile-vod-${dataCy}` : ''"
    href=""
    @click.prevent="goToVodSource"
  >
    <LazyImage v-if="image" :src="image" :with-bg="false" tag="div" class="image" />
    <div class="accent" :style="{ backgroundColor: color }" />
    <LazyImage v-if="logo" :src="logo" :with-bg="false" tag="div" class="logo" />
    <div v-if="title" v-html="title" />
  </a>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import LazyImage from 'src/components/LazyImage.vue';

@Component({
  components: { LazyImage },
})
export default class TileVod extends SequoiaComponent {
  @Prop({ required: true })
  sourceId!: string;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  logo!: string;

  @Prop({ required: true })
  image!: string;

  @Prop({ required: true })
  color!: string;

  @Prop()
  dataCy?: string;

  goToVodSource() {
    this.$emit('gaTileClick');
    this.$router.push(`/vod/${this.sourceId}`);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/overlays';
@import 'src/styles/placeholders-and-mixins/shadows';

$transition: var(--ease-out) 0.15s;

.tile-vod {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 128px;
  overflow: hidden;
  color: var(--c-dark-font-primary);
  cursor: pointer;
  border-radius: 4px;
  transition: $transition;
  transform: perspective(1000px);
  backface-visibility: hidden;

  @include tablet {
    height: 80px;
  }

  @include mobile {
    height: 72px;
  }

  &:active {
    transform: scale(0.96);
  }

  @include devices-with-hover {
    &:hover {
      transform: scale(1.04);
    }

    &:active {
      transform: scale(1.02);
    }
  }

  &.overlay-default-before {
    &::before {
      border-radius: 4px;
    }

    @include overlay-color-before(var(--c-overlay-lvl-60));
  }

  .image,
  .accent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  .image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .accent {
    z-index: var(--z-1);
    opacity: 0.3;
  }

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: var(--z-2);
    width: 192px;
    height: 64px;
    background: no-repeat center;
    filter: brightness(0%) invert(100%);
    background-size: contain;
    transition: $transition;
    transform: translate(-50%, -50%);
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-backface-visibility: hidden;
    /* stylelint-ensable property-no-vendor-prefix */

    @include desktop-s {
      width: 168px;
      height: 56px;
    }

    @include tablet {
      width: 144px;
      height: 48px;
    }

    @include mobile {
      width: 108px;
      height: 36px;
    }

    &::v-deep .image {
      background-size: contain;
    }
  }

  @media (min-width: #{$desktop-s-min}) {
    &:hover .logo {
      transform: translate(-50%, -60%);
    }
  }

  .title {
    @extend %caption1;

    position: absolute;
    bottom: 10%;
    z-index: var(--z-2);
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: $transition;
  }

  @media (min-width: #{$desktop-s-min}) {
    &:hover .title {
      opacity: 1;
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    @extend %shadow-default-light;
  }

  &.dark {
    @extend %shadow-default-dark;
  }
}
</style>
