<template>
  <div class="tile-view-more" @click="goToMore">
    <ButtonCircle direction="right" iconType="arrow" tag="div" />
    <span v-html="text" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import ButtonCircle from 'src/components/ui/buttons/ButtonCircle.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { ButtonCircle },
})
export default class TileViewMore extends Vue {
  @Prop({ required: true })
  text!: string;

  @Prop({ required: true })
  link!: string;

  goToMore() {
    this.$emit('click');
    if (this.link) {
      this.$router.push(this.link);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.tile-view-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 4px;
  @include body2;

  @include devices-with-hover {
    &:hover {
      .button::before {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        transform: scale(1.2);
      }
    }
  }

  &:active {
    .button::before {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      transform: scale(1.15);
    }
  }
}

.light {
  .tile-view-more {
    background-color: var(--alpha-light-3);

    span {
      color: var(--alpha-light-7);
    }
    @include devices-with-hover {
      &:hover span {
        color: var(--alpha-light-8);
      }
    }

    &:active span {
      color: var(--alpha-light-9);
    }
  }
}

.dark {
  .tile-view-more {
    background-color: var(--alpha-dark-3);

    span {
      color: var(--alpha-dark-7);
    }
    @include devices-with-hover {
      &:hover span {
        color: var(--alpha-dark-8);
      }
    }

    &:active span {
      color: var(--alpha-dark-9);
    }
  }
}
</style>
