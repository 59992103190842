<template>
  <div
    v-if="$store.vod && !isEmpty(sources)"
    class="tiles-vod-navigation-group"
    :data-cy="dataCy ? `tiles-vod-navigation-group-${dataCy}` : ''"
  >
    <h3 v-if="showHeader" class="h3" v-html="getTranslation('vod')" />
    <div class="row">
      <TileVod
        v-for="(source, i) in sources"
        :key="source.id + i.toString()"
        :class="[
          { 'col-desktop-12 col-tablet-8 col-mobile-4': getObjectSize(sources) === 1 },
          { 'col-desktop-6 col-tablet-4 col-mobile-2': getObjectSize(sources) === 2 },
          {
            'col-desktop-4 col-tablet-4 col-mobile-2': [3, 5, 6, 9].includes(
              getObjectSize(sources)
            ),
          },
          {
            'col-desktop-3 col-tablet-4 col-mobile-2': [4, 7, 8, 10].includes(
              getObjectSize(sources)
            ),
          },
        ]"
        :source-id="source.id"
        :title="source.customTitle ? getByLanguage(source.customTitle) || '' : ''"
        :image="getByLanguage(source.imageTileVod) ? getByLanguage(source.imageTileVod).url : ''"
        :logo="getByLanguage(source.logo) ? getByLanguage(source.logo).url : ''"
        :color="source.color ? source.color.basic : ''"
        :data-cy="dataCy ? `${dataCy}` : ''"
        @gaTileClick="$emit('gaTileClick', source.id)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import TileVod from 'src/components/ui/tiles/TileVod.vue';
import { selectors } from 'src/store/selectors';
import isEmpty from 'lodash/isEmpty';

@Component({
  components: { TileVod },
})
export default class TilesVodNavigationGroup extends SequoiaComponent {
  isEmpty = isEmpty;

  @Prop({ default: true })
  showHeader!: boolean;

  @Prop()
  dataCy?: string;

  get sources() {
    return selectors.vod.sourcesWithoutArchiveSelector(this.$store);
  }

  getObjectSize(obj: Record<any, any>) {
    let size = 0;
    let key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.tiles-vod-navigation-group {
  .tile-vod {
    flex-grow: 1;
    margin-bottom: 24px;

    @include mobile-and-tablet {
      margin-bottom: 16px;
    }
  }
}
</style>
