<template>
  <div class="vod-recommendations" data-cy="vod-recommendations">
    <LoaderSpinner v-if="!recommendationsLoaded" />
    <section
      v-else
      v-for="(vod, index) in vodWithPosters"
      :key="'vod' + index"
      class="pb-64 pb-tablet-48 pb-mobile-48 overflow-hidden"
      data-cy="vod-recommendation"
      :class="{ 'pb-16': index === vodWithPosters.length - 1 }"
    >
      <SectionHeaderLink
        class="with-side-padding"
        :text="vod.title"
        :link="vod.link"
        @click.native="
          gaEvent({
            action: 'Клик по заголовку стрипа',
            category: gaEventCategory,
            strip_name: vod.title,
          })
        "
      />

      <HomeContentSlider type="adaptive" :theme-forced="theme" @gaScroll="gaScroll(vod.title)">
        <TilePoster
          v-for="poster in getVodPosters(vod)"
          :key="poster.id"
          class="col-desktop-2 col-tablet-2 col-mobile-2"
          :width-proportion="posterWidthProportion(vod.source)"
          :height-proportion="posterHeightProportion(vod.source)"
          :title="poster.title"
          :original-title="poster.originalTitle"
          :link="poster.link"
          :image="poster.image.path"
          :image-provider="poster.image.type"
          :logo="vod.logo"
          :rating-imdb="poster.ratingImdb"
          :rating-kinopoisk="poster.ratingKinopoisk"
          :countries="poster.countries"
          :years="poster.years"
          :genres="poster.genres"
          :duration="poster.duration"
          :title-branding-methods="poster.brandingMethods"
          @click="
            gaEvent({
              action: 'Клик по тайтлу в стрипе',
              category: gaEventCategory,
              strip_name: vod.title,
              title_id: poster.link.split('/')[3],
              vod_name: vod.source,
            })
          "
        />

        <div
          v-if="vod.posters.length > DEFAULT_NUMBER_OF_SLIDES"
          class="col-desktop-2 col-tablet-2 col-mobile-2"
        >
          <div
            class="tile-view-more-wrapper"
            :style="{
              paddingTop: `${
                (posterHeightProportion(vod.source) / posterWidthProportion(vod.source)) * 100
              }%`,
            }"
          >
            <TileViewMore
              class="tile-view-more"
              :text="getTranslation('show_more')"
              :link="vod.link"
              @click="
                gaEvent({
                  category: gaEventCategory,
                  action: 'Клик по кнопке ”Показать еще”',
                  strip_name: vod.title,
                })
              "
            />
          </div>
        </div>
      </HomeContentSlider>
    </section>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import * as api from 'src/api';
import { Watch, Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import {
  DEFAULT_NUMBER_OF_SLIDES,
  DEFAULT_VOD_CATEGORIES,
  HOME_SCREEN_TILES_COUNT,
} from 'src/constants';
import SectionHeaderLink from 'src/components/ui/SectionHeaderLink.vue';
import TilePoster from 'src/components/ui/tiles/TilePoster.vue';
import TileViewMore from 'src/components/ui/tiles/TileViewMore.vue';
import HomeContentSlider from 'src/components/home/HomeContentSlider.vue';
import { TVODTitlePreviewEnhanced } from 'src/api/vod/types';
import { VODCategoryPreview, VODGenre } from 'src/models/ts/vod/v2/vod';
import { getSeoBot } from 'src/utils/platform-detector';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';

@Component({
  components: {
    HomeContentSlider,
    SectionHeaderLink,
    TilePoster,
    TileViewMore,
    LoaderSpinner,
  },
})
export default class VodRecommendations extends SequoiaComponent {
  // uncomment when MEGOGO comes back
  // megogoCategoryId = wlDetector().isKt ? 'ktvodcat' : 6;
  DEFAULT_NUMBER_OF_SLIDES = DEFAULT_NUMBER_OF_SLIDES;
  vodWithPosters: Array<{
    source: string;
    title: string;
    link: string;
    logo: string;
    posters: any;
  }> = [];

  vodPresets: {
    [sourceId: string]: {
      title: string;
      link: string;
      fetchPostersParams: { categoryId: string; limit: number; offset: number; sort: string };
    };
  } = {
    amedia: {
      title: this.getTranslation('amedia_strip'),
      link: '/vod/amedia',
      fetchPostersParams: {
        categoryId: DEFAULT_VOD_CATEGORIES.amedia,
        limit: HOME_SCREEN_TILES_COUNT,
        offset: 0,
        sort: 'popular',
      },
    },
    start: {
      title: this.getTranslation('start_strip'),
      link: '/vod/start',
      fetchPostersParams: {
        categoryId: DEFAULT_VOD_CATEGORIES.start,
        limit: HOME_SCREEN_TILES_COUNT,
        offset: 0,
        sort: 'popular',
      },
    },
    ivi: {
      title: this.getTranslation('ivi_strip'),
      link: '/vod/ivi',
      fetchPostersParams: {
        categoryId: DEFAULT_VOD_CATEGORIES.ivi,
        limit: HOME_SCREEN_TILES_COUNT,
        offset: 0,
        sort: 'pop',
      },
    },
    megogo: {
      title: this.getTranslation('megogo_strip'),
      link: `/vod/megogo`, // megogo or made-in-kt
      fetchPostersParams: {
        categoryId: DEFAULT_VOD_CATEGORIES.megogo,
        limit: HOME_SCREEN_TILES_COUNT,
        offset: 0,
        sort: 'popular',
      },
    },
    moretv: {
      title: this.getTranslation('moretv_strip'),
      link: `/vod/moretv`,
      fetchPostersParams: {
        categoryId: DEFAULT_VOD_CATEGORIES.moretv,
        limit: HOME_SCREEN_TILES_COUNT,
        offset: 0,
        sort: 'popular',
      },
    },
    premier: {
      title: this.getTranslation('premier_strip'),
      link: `/vod/premier`,
      fetchPostersParams: {
        categoryId: DEFAULT_VOD_CATEGORIES.previer,
        limit: HOME_SCREEN_TILES_COUNT,
        offset: 0,
        sort: 'popular',
      },
    },
  };

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop({ required: true, default: '' })
  gaEventCategory!: string;

  @Watch('sourcesLoaded')
  async onVodSourcesChange() {
    this.vodWithPosters = await this.getVodWithPosters();
  }

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  get sources() {
    return selectors.vod.sourcesWithoutArchiveSelector(this.$store);
  }

  get sourcesLoaded() {
    return selectors.vod.sourcesLoadedSelector(this.$store);
  }

  get recommendationsLoaded() {
    return this.$store.vod.states.recommendationsLoaded;
  }

  async serverPrefetch() {
    if (getSeoBot()) {
      await this.onVodSourcesChange();
    }
  }

  async mounted() {
    await this.onVodSourcesChange();
  }

  getVodPosters(vod: any) {
    return vod.posters.slice(0, DEFAULT_NUMBER_OF_SLIDES - 1);
  }

  posterWidthProportion(source: string) {
    return selectors.vod.posterWidthProportionSelector(this.$store, source);
  }

  posterHeightProportion(source: string) {
    return selectors.vod.posterHeightProportionSelector(this.$store, source);
  }

  getVodWithPresets() {
    const sources = Object.keys(this.sources);
    return Object.keys(this.vodPresets)
      .filter((sourceId) => sources.includes(sourceId))
      .map((sourceId) => ({
        sourceId,
        ...this.sources[sourceId],
        ...this.vodPresets[sourceId],
      }));
  }

  async getVodWithPosters() {
    const postersPromises = this.getVodWithPresets().map((vod) =>
      this.fetchPosters(vod.sourceId, vod.fetchPostersParams).catch(() => {
        // do nothing, it's ok if some vod sources were unable to load
      })
    );

    const posters = await Promise.all(postersPromises).finally(() => {
      this.$store.vod.states.recommendationsLoaded = true;
    });

    return this.getVodWithPresets()
      .map((vod, index) => ({
        source: vod.sourceId,
        title: vod.title,
        link: vod.link,
        logo: actions.vod.getSourceLogoUrl(this.$store, vod.sourceId),
        posters: posters[index],
      }))
      .filter((vod) => vod.posters?.length);
  }

  async fetchPosters(sourceId: string, params: any) {
    const response = await api.vod.getTitles(sourceId, { params });
    return (
      response?.titles?.map((one: TVODTitlePreviewEnhanced) => this.preparePoster(one, sourceId)) ||
      []
    );
  }

  preparePoster(title: TVODTitlePreviewEnhanced, sourceId: string) {
    return {
      image: this.extractPosterImage(title),
      title: title.title,
      originalTitle: title.originalTitle,
      link: `/vod/${sourceId}/${title.id}`,
      duration: title.duration,
      countries: title.countries,
      years: title.years,
      genres: this.extractPosterGenres(title, sourceId),
      ratingImdb: title.ratingImdb,
      ratingKinopoisk: title.ratingKp,
      brandingMethods: title.brandingMethods,
    };
  }

  extractPosterImage(title: any) {
    let image = title.posters?.length ? title.posters[0] : null;
    if (!image && title.thumbnails?.length) {
      image = title.thumbnails[0];
    }
    if (!image && title.additionalImages && title.additionalImages.length) {
      image = title.additionalImages[0];
    }
    return image ? image : { path: '', type: '' };
  }

  extractPosterGenres(title: TVODTitlePreviewEnhanced, sourceId: string) {
    let genres: VODGenre[] | VODCategoryPreview[] = [];
    if (sourceId === 'start' && title.categories) {
      genres = [...title.categories];
    } else if (sourceId !== 'start' && title.genres) {
      genres = [...title.genres];
    }
    return genres;
  }

  gaScroll(id: string) {
    this.gaEvent({ action: 'Скролл стрипа', category: this.gaEventCategory, strip_name: id });
  }
}
</script>

<style lang="scss">
@import 'src/styles/pages/home';
</style>

<style lang="scss" scoped>
.vod-recommendations {
  .tile-view-more-wrapper {
    position: relative;
    flex: 1;
  }

  .tile-view-more {
    position: absolute;
    top: 0;
  }

  .tile-posters-mobile {
    display: flex;
    flex-wrap: nowrap;
    padding-top: 20px;
    padding-bottom: 100px;
    margin-top: -20px;
    margin-bottom: -100px;
    overflow: auto;

    > * {
      flex-shrink: 0;
    }
  }
}
</style>
